interface SplitGridProps {
  children: React.ReactNode[] | React.ReactNode;
}

export function SplitGrid(props: SplitGridProps) {
  const { children } = props;

  if (!Array.isArray(children) || children.length !== 2) {
    throw new Error('SplitGrid expects exactly two children.');
  }

  return (
    <div className='grid h-screen grid-cols-1 gap-4 bg-gradient-to-b from-sidebar-bg-from to-sidebar-bg-to md:grid-cols-2'>
      <div className='flex items-center justify-center p-6'>{children[0]}</div>

      <div className='animate-slide-in-right m-auto hidden  p-6 md:block'>{children[1]}</div>
    </div>
  );
}
